<template>
  <div class="newsDetail">
    <div class="newsC">
      <div class="tw">
        <div class="topTit">
          <div class="tit">
            {{ news.title }}
          </div>
          <div class="time">
            {{ news.editor }}&nbsp;&nbsp;·&nbsp;&nbsp;{{
              news.pubtime
            }}&nbsp;&nbsp;·&nbsp;&nbsp;{{ news.browseQua }}人读过
          </div>
        </div>
        <!-- <div class="detailImg" v-show="news.imgUrl">
          <img v-lazy="news.imgUrl" alt="" />
        </div> -->
        <div class="detailTex" v-show="news.summary">
          {{
            news.summary.length > 100
              ? news.summary.slice(0, 100) + "..."
              : news.summary
          }}
        </div>
        <div class="w" v-html="news.content"></div>
      </div>
      <div class="relevantNews">
        <div class="rNews">
          <div>相关新闻</div>
          <ul>
            <li v-for="(item, k) in newsList" :key="k">
              <div class="txt" @click="handleNews(item.newsId)">
                <div class="sign fl">{{ k + 1 }}</div>
                {{
                  item.title.length > 25
                    ? item.title.substr(0, 25) + "..."
                    : item.title
                }}
              </div>
              <div class="num">{{ item.browseQua }}人读过</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // activeClass:0,
      news: [],
      newsList: [],
    };
  },
  methods: {
    handleNews(newsId) {
      // this.$router.push(`/news/detail?newsId=${newsId}`)
      this.$router.push({
        path: "",
        query: {
          newsId: newsId,
        },
      });
    },
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  mounted() {
    this.sideActive = 1;
    this.$http.getNewById({ newsId: this.$route.query.newsId }).then((res) => {
      if (res.success) {
        this.news = res.news;
        this.newsList = res.newsList;
      }
    });
  },
};
</script>
<style lang="scss">
@import "../scss/newsdetil.scss";
</style>