<template>
  <div>
    <div class="tit">
      <div style="font-size: 18px">公司简介</div>
    </div>
    <div class="cont">
      湖南新讯信息科技有限公司（以下简称新讯科技）成立于2017年，是一家集研发、生产、销售于一体的大数据企业。公司位于湖南省桃源县高新技术产业开发区，占地面积50余亩，园区建设包括基础数据处理服务中心、数据应用研发服务中心、业务场景综合应用中心、智慧云服务中心，致力为客户提供专业的数据处理、技术开发及数据解决方案。
    </div><br>
    <div class="cont">
      公司自成立以来，始终秉持以数据为基础，注重技术及数据储备能力投入，为政府机关、教育科研机构、金融机构、企事业单位等提供数据加工、数据应用、数据可视化、数据分析、数据云等一体化服务。其基础数据处理中心拥有先进的专业技术、一流的专业设备、高效的管理经验和一支专业化的管理队伍，特别在专业处理报刊、档案、图书、文献等大型数字化工程及全文检索制作等方面拥有独特的优势。
    </div><br>
    <div class="cont">
      “长风破浪会有时，直挂云帆济沧海”，新讯科技致力于助力地方信息化建设升级，推动数据业务高速、健康有序发展，打造数据经济圈，带动经济建设和城市发展，全力构建大数据服务生态网，实现区域内全产业链升级，努力成长为一家以技术创新为核心竞争力，兼具绿色、节能的环境友好型高新技术企业。
    </div>
    <div class="banner">
      <el-carousel :interval="4000" type="card">
        <el-carousel-item v-for="item in newsImages" :key="item.id">
          <h3 class="medium">
            <img :src="item.url" alt="" style="width: 100%" />
          </h3>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  name: "about_intro",
  data() {
    return {
      // 轮播图
      newsImages: [
        { url: require("../assets/news.png"), id: 1 },
        { url: require("../assets/news1.png"), id: 2 },
        { url: require("../assets/news2.png"), id: 3 },
        // { url: require("../assets/news.png"), id: 4 },
      ],
    };
  },
};
</script>